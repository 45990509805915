import {getAuthKey} from "../firebase/firebase";
import {serviceUrl} from "../defaults/AppConfig";

const mainUrl = serviceUrl;

const login = async (url, loginType) => {
    const response = await fetch(mainUrl + url, {
        method: 'POST',
        body: JSON.stringify(loginType),
        headers: {'Content-Type': 'application/json'},
    });
    return await response.json();
};

const doGet = async (url) =>{
    try{
        const key = await getAuthKey()
        const response = await fetch(mainUrl+url, {
            headers: {
                'method': 'GET',
                'Authorization': 'jwt '+key
            }
        })
        const responseText = await response.text();

        try {
            // Try to parse the text as JSON
            return JSON.parse(responseText);
        } catch {
            // If the text cannot be parsed as JSON, return the text as is
            return responseText;
        }
    }catch(err) {
        console.error('Error:', err);
        throw err;  // re-throw the error so the caller can handle it
    }

}


const doGetSurvey = async (url, key) =>{
    try{
        const response = await fetch(mainUrl+url, {
            headers: {
                'method': 'GET',
                'Authorization': 'jwt '+key
            }
        })
        return await response.json();
    }catch(err) {
        console.error('Error:', err);
        throw err;  // re-throw the error so the caller can handle it
    }

}

const doPost = async (url, formData) =>{
    const key = await getAuthKey()
    let response = await fetch(mainUrl+url, {
        method: 'POST',
        body: formData,
        headers: {
            'Authorization': 'jwt '+key
        }
    })
    return await response.json()
}

const doPostNoResponse = async (url, formData) =>{
    const key = await getAuthKey()
    await fetch(mainUrl+url, {
        method: 'POST',
        body: formData,
        headers: {
            'Authorization': 'jwt '+key
        }
    })
}

const doPostBody = async (url, data) =>{
    let header = await getAuthKey()
    let response = await fetch(mainUrl+url, {
        method: "POST",
        body: JSON.stringify(data),
        headers: new Headers({"Authorization" : "jwt "+header, "Content-Type": "application/json"})
    })
    return await response.json()
}

const doPostBodySurvey = async (url, data, key) =>{
    let response = await fetch(mainUrl+url, {
        method: "POST",
        body: JSON.stringify(data),
        headers: new Headers({"Authorization" : "jwt "+key, "Content-Type": "application/json"})
    })
    return await response.json()
}

function downloadFileFromUrlPost({url, data}){
    return getAuthKey().then(key =>{
        return fetch(mainUrl+url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'jwt ' + key,
            },
        })
            .then(res => {
                if (!res.ok) {
                    // If the HTTP status is not OK (200), throw an error
                    throw new Error(res.status + ': ' + res.statusText);
                }
                return res.blob();
            })
            .then(blob => {
                return blob;
            })
            .catch(err => {
                console.error('There was an error!', err);
            });
    })
}

function downloadFileFromUrlGet(url){
    return getAuthKey().then(key =>{
        return fetch(mainUrl+url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'jwt ' + key,
            },
        })
            .then(res => {
                if (!res.ok) {
                    // If the HTTP status is not OK (200), throw an error
                    throw new Error(res.status + ': ' + res.statusText);
                }
                return res.blob();
            })
            .then(blob => {
                return blob;
            })
            .catch(err => {
                console.error('There was an error!', err);
            });
    })
}

const uploadPostImages = async (formData) =>{
    const key = await getAuthKey();
   const result = await fetch(mainUrl+"stream/upload-images", {
       method : 'post',
       body: formData,
       headers:{
           'Authorization': 'jwt '+key
       }
   })
    return await result.json();
}
const postNoAuth = async (url, data) => {
    let response = await fetch(mainUrl + url, {
        method: 'POST',
        body: data,
    });
    return await response.json();
}

const uploadDocuments = async (documents) =>{
    const key = await getAuthKey();
    const result = await fetch(mainUrl+"documents/admin/upload", {
        method : 'post',
        body: documents,
        headers:{
            'Authorization': 'jwt '+key
        }
    })
    return await result.json();
}

const viewImage = async (type, image) => {
    const response = await fetch(mainUrl + `stream/view-image/${type}/${image}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'jwt ' + await getAuthKey(),
        },
    });
    return await response.blob();
}

const viewITImage = async (id) => {
    const response = await fetch(mainUrl + `it/image/${id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'jwt ' + await getAuthKey(),
        },
    });
    return await response.blob();
}

const imageUploadHandler = (blobInfo, progress) => new Promise(async (resolve, reject) => {
    getAuthKey().then(key =>{
        const xhr = new XMLHttpRequest();
        xhr.withCredentials = true;
        xhr.open('POST', mainUrl+'message-admin/upload-image');

        // Assuming the getAuthToken() returns a promise that resolves with the auth token
        xhr.setRequestHeader('Authorization', 'jwt ' + key);

        xhr.upload.onprogress = (e) => {
            progress(e.loaded / e.total * 100);
        };

        xhr.onload = () => {
            if (xhr.status === 403) {
                reject({ message: 'HTTP Error: ' + xhr.status, remove: true });
                return;
            }

            if (xhr.status < 200 || xhr.status >= 300) {
                reject('HTTP Error: ' + xhr.status);
                return;
            }

            const json = JSON.parse(xhr.responseText);

            if (!json || typeof json.url != 'string') {
                reject('Invalid JSON: ' + xhr.responseText);
                return;
            }

            resolve(json.url);
        };

        const formData = new FormData();
        formData.append('file', blobInfo.blob(), blobInfo.filename());

        xhr.send(formData);
    })
});
const doGetVerifyToken = async (url, token) => {
    const response = await fetch(mainUrl + url, {
        method: 'GET',
        headers: {
            Authorization: 'jwt ' + token,
        },
    });
    return await response.json();
}

export {doGetVerifyToken, doPostBodySurvey, doGetSurvey, login, doGet, mainUrl, doPost, doPostBody, downloadFileFromUrlPost, downloadFileFromUrlGet, uploadPostImages, viewImage, uploadDocuments, imageUploadHandler, viewITImage, doPostNoResponse, postNoAuth};
