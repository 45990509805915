import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { register } from './serviceWorker';
import NotificationProvider from "./app/componants/notifications/NotificationProvider";
import {UserPreferencesProvider} from "./app/componants/context/UserPreferencesProvider";
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <UserPreferencesProvider>
          <NotificationProvider>
             <App />
          </NotificationProvider>
      </UserPreferencesProvider>
  </React.StrictMode>
);
register()
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
