import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faPlus, faChevronLeft, faChevronRight, faStore} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import Heading from "../Heading";
import MySpinner from "../MySpinner";
import { doGet } from "../../services/rest";
import './MarketPlace.css';
import {serviceUrl} from "../../defaults/AppConfig";

function MarketPlace() {
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [marketplaceItems, setMarketPlaceItems] = useState({});
    const [currentImageIndexes, setCurrentImageIndexes] = useState({});

    const navigation = useNavigate();

    useEffect(() => {
        loadMarketplaceItems();
    }, [page]);

    const loadMarketplaceItems = () => {
        setLoading(true);
        doGet(`marketplace/items?page=${page}`).then(data => {
            setMarketPlaceItems(data);
            setLoading(false);
        });
    };

    const handleNextImage = (itemId) => {
        setCurrentImageIndexes(prev => ({
            ...prev,
            [itemId]: ((prev[itemId] || 0) + 1) % marketplaceItems.content.find(item => item.id === itemId).images.length
        }));
    };

    const handlePrevImage = (itemId) => {
        setCurrentImageIndexes(prev => ({
            ...prev,
            [itemId]: ((prev[itemId] || 0) - 1 + marketplaceItems.content.find(item => item.id === itemId).images.length) %
            marketplaceItems.content.find(item => item.id === itemId).images.length
        }));
    };

    if (loading) {
        return (
            <>
                <Heading title={'Market Place'}/>
                <MySpinner />
            </>
        );
    }

    if(marketplaceItems?.content?.length === 0) {
        return (
            <>
                <Heading
                    title={'Market Place'}
                    icons={[
                        <FontAwesomeIcon icon={faStore} style={{paddingRight: 10}} onClick={() => navigation('/marketplace/mine')} cursor={'pointer'} />,
                        <FontAwesomeIcon
                            cursor={'pointer'}
                            onClick={() => navigation("/marketplace/new")}
                            icon={faPlus}
                        />,
                    ]}
                />
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    minHeight: '100vh'
                }}>
                    <p style={{ textAlign: 'center' }}>
                        It looks like there is currently nothing here. Press the + button in the right hand corner to add something.
                    </p>
                </div>
            </>
        )
    }

    return (
        <>
            <Heading
                title={'Market Place'}
                icons={[
                    <FontAwesomeIcon icon={faStore} style={{paddingRight: 10}} onClick={() => navigation('/marketplace/mine')} cursor={'pointer'} />,
                    <FontAwesomeIcon
                        cursor={'pointer'}
                        onClick={() => navigation("/marketplace/new")}
                        icon={faPlus}
                    />,
                ]}
            />
            <div className={'inner-container'}>
                <div className="grid">
                    {marketplaceItems.content.map((item) => (
                        <div style={{width: 220}}
                             key={item.id}
                            className="bg-white rounded-lg shadow-md overflow-hidden cursor-pointer"
                            onClick={() => navigation(`/marketplace/${item.id}`)}
                        >
                            <div className="relative w-full pt-[100%]">
                                {item.images && item.images.length > 0 && (
                                    <>
                                        <img
                                            src={serviceUrl+`marketplace/images/thumbnail/${item.images[currentImageIndexes[item.id] || 0]}`}
                                            alt={item.title}
                                            className="absolute top-0 left-0 w-full h-full object-cover"
                                        />
                                        {item.images.length > 1 && (
                                            <>
                                                <button
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handlePrevImage(item.id);
                                                    }}
                                                    className="absolute left-2 top-1/2 transform bg-black/50 text-white rounded-full p-2"
                                                >
                                                    <FontAwesomeIcon icon={faChevronLeft} />
                                                </button>
                                                <button
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleNextImage(item.id);
                                                    }}
                                                    className="absolute right-2 top-1/2 transform bg-black/50 text-white rounded-full p-2"
                                                >
                                                    <FontAwesomeIcon icon={faChevronRight} />
                                                </button>
                                            </>
                                        )}
                                    </>
                                )}
                            </div>
                            <div className="p-4">
                                <h3 className="text-lg font-semibold mb-2">{item.title}</h3>
                                <p className="text-xl font-bold text-green-600 mb-2">
                                    ${item.price.toLocaleString('en-US', {minimumFractionDigits: 2})}
                                </p>
                                <div className="text-sm text-gray-600">
                                    {item.contactPhoneNumber && (
                                        <p className="mb-1">📞 {item.contactPhoneNumber}</p>
                                    )}
                                    {item.contactEmail && (
                                        <p className="items-center gap-2" style={{display: 'flex'}}>
                                            <div>✉️</div>
                                            <div>{item.contactEmail}</div>
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                <div className="flex justify-center items-center mt-8 gap-4">
                    <button
                        onClick={() => setPage(prev => Math.max(0, prev - 1))}
                        disabled={page === 0}
                        className="px-4 py-2 bg-blue-500 text-white rounded-lg disabled:bg-gray-300"
                    >
                        <FontAwesomeIcon icon={faChevronLeft} className="mr-2" />
                        Previous
                    </button>
                    <span className="text-gray-600">Page {page + 1}</span>
                    <button
                        onClick={() => setPage(prev => prev + 1)}
                        disabled={marketplaceItems.last}
                        className="px-4 py-2 bg-blue-500 text-white rounded-lg disabled:bg-gray-300"
                    >
                        Next
                        <FontAwesomeIcon icon={faChevronRight} className="ml-2" />
                    </button>
                </div>
            </div>
        </>
    );
}

export default MarketPlace;
