import {useAuthState} from "react-firebase-hooks/auth";
import { signInWithCustomToken } from 'firebase/auth';
import {auth} from "./firebase/firebase";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import "./css/main-app.css";
import Logo from './images/GSC.png'
import MySpinner from "./componants/MySpinner";
import {doGetVerifyToken, login, postNoAuth} from "./services/rest";
import {PublicClientApplication} from "@azure/msal-browser";

function Login() {

    const [user, loading] = useAuthState(auth);

    const navigate = useNavigate();

    const [loginData, setLoginData] = useState()

    const [isLoading, setIsLoading] = useState(false)

    const [errorMessage, setErrorMessage] = useState(null)

    const [checkForOauth, setCheckForOauth] = useState(false);

    const [isOauth, setIsOauth] = useState(false);

    const msalConfig = {
        auth: {
            clientId: "79faa56a-69d6-4966-8bdf-b17214a3488d", // Replace with your registered app's client ID
            authority: "https://login.microsoftonline.com/71159daa-b628-47be-98d9-8b2f09833298", // Replace with your tenant ID
            redirectUri: process.env.REACT_APP_REDIRECT, // The URL Entra ID should redirect to after authentication
            postLogoutRedirectUri: process.env.REACT_APP_REDIRECT, // The URL Entra ID should redirect to after logout
        },
        cache: {
            cacheLocation: "memory", // You can choose "localStorage" as well
            storeAuthStateInCookie: false,
        },
        system: {
            allowRedirectInIframe: false,
            loggerOptions: {
                loggerCallback: (level, message, containsPii) => {
                    if (containsPii) {
                        return;
                    }
                    console.log(message, containsPii);
                },
            },
        },
    };

    const msalInstance = new PublicClientApplication(msalConfig);

    useEffect(()=>{
        if(loading) return;
        if(user) navigate("/")
    }, [user, loading, navigate])

    useEffect(()=>{
        const loginOauth2 = async () => {
            try{
                window.alert("It looks like you are logging in as an employee.  You will now be redirected to microsoft to log in.");
                await msalInstance.initialize();
                let loginRequest = {
                    scopes: ['openid'], // The permissions you're requesting
                    prompt: "login", // Optional: controls the prompt behavior
                    loginHint: loginData.username
                };

                const authResult = await msalInstance.loginPopup(loginRequest);
                if (authResult) {
                    setIsLoading(true);
                    const getToken = await doGetVerifyToken('login/oauth2/validate', authResult.accessToken);
                    if(getToken.success){
                        signInWithCustomToken(auth, getToken.authToken)
                            .then(() => {
                                setIsLoading(false);
                            })
                            .catch(error => {
                                setErrorMessage(error.code)
                                setCheckForOauth(false);
                                setIsLoading(false);
                            });
                    }
                }
            }catch(error){
                setErrorMessage(error.code)
                setCheckForOauth(false);
                setIsLoading(false);
            }

        }
        if(isOauth){
            loginOauth2();
        }

    }, [isOauth])

    const onChange = event => {
        setLoginData(prevState => ({
            ...prevState,
            [event.target.name] : event.target.value
        }))
    }

    const onSubmit = async event =>{
        event.preventDefault()
        if(loginData?.username === undefined){
            alert("Username is required.")
            return
        }
        if(loginData?.password === undefined){
            alert("Password is required.")
            return
        }
        setIsLoading(true)

        const response = await login("login", loginData)
        if (response.success) {
            signInWithCustomToken(auth, response.authToken)
                .then(() => {
                    setIsLoading(false);
                })
                .catch(error => {
                    setErrorMessage(error.code)
                    setIsLoading(false)
                });
        } else {
            setErrorMessage(response.message ?? "Error Logging your on, please try again.")
            setIsLoading(false)
        }
    }

    const handleKeyPress = event =>{
        if(event.key === 'Enter'){
            onSubmit(event)
        }
    }

    const checkForOauthLogin = async () => {
        if(!loginData.username){
            window.alert("User name cannot be blank")
            return;
        }
        try{
            const formData = new FormData();
            formData.append('email', loginData.username);
            const response = await postNoAuth('login/oauth2', formData);
            setIsOauth(response.oauth2);
            setCheckForOauth(true);
        }catch(e){
            console.log(e);
        }finally{
            setIsLoading(false);
        }
    }

    return(<>
        <div className={"main-heading-login"}><h3>Garden Spot Communities</h3></div>
        {isLoading ? MySpinner() :
        <div className="container">
            <div className="header">
                <img src={Logo} className={"logo-image"} alt={"GSC Logo"}/>
            </div>
            <div className={"label-align-left"}>
                <label htmlFor="uname"><b>Username</b></label>
                <input className={"login"} onChange={onChange} type="text" placeholder="Enter Username" name="username" required/>
            </div>
            {(checkForOauth && !isOauth) && <div className={"label-align-left"}>
                <label htmlFor="psw"><b>Password</b></label>
                <input className={'login'} onChange={onChange} type="password" onKeyDown={handleKeyPress} placeholder="Enter Password" name="password" required/>
            </div> }
            {errorMessage != null ? <div style={{color : 'red'}}>{errorMessage}</div> : ''}
            <button onClick={(event) =>(checkForOauth && !isOauth) ? onSubmit(event) : checkForOauthLogin()} type="submit" class="btn">{(checkForOauth && !isOauth) ? `Log In` : `Next`}</button>
            <div style={{cursor:'pointer'}}>By Logging in you agree to this apps terms and conditions.</div>
            <div style={{paddingTop: "5px", cursor: "pointer"}}>Forgot username or password.</div>
        </div>}
        <div className={"bottom-footer"}>&copy; {new Date().getFullYear()} Garden Spot Communities</div>
    </>)
}

export default Login
