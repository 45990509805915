import {doGet} from "./rest";
import Cookies from "universal-cookie";

const cookies = new Cookies(null, {path: '/'})
const loadMenu = async () =>{
    window.onbeforeunload = function(event) {
        localStorage.removeItem('menu');
    };

    if(localStorage.getItem("menu") !== null){
        return JSON.parse(localStorage.getItem("menu"))
    }
    const res = await doGet("menu?version=20250317")
    const usedIds = []
    let menuItems = []
    const typeIds = []

    res.locationsSet.map(data => {
        data.menuItems.forEach(item =>{
            if(!usedIds.includes(item.id)){
                usedIds.push(item.id)
                if(item.menuItemTypes.allowMultiple || !typeIds.includes(item.menuItemTypes.id)){
                    menuItems.push(item)
                    if(!item.menuItemTypes.allowMultiple){
                        typeIds.push(item.menuItemTypes.id)
                    }
                }
            }
        })
    })
    menuItems.sort((a, b) => {
        if (a.order === b.order) {
            return a.title.localeCompare(b.title, 'en', { sensitivity: 'base' });
        }
        return a.order - b.order;
    });
    if(res.userSecurities.some(obj => obj.securityKey === "ROLE_USERS")){
        menuItems.push({icon: 'fa-user', title:'User Admin', menuItemTypes: {jsRoute: '/user-admin'}})
    }
    if(res.userSecurities.some(obj => obj.securityKey === "ROLE_QR_ADMIN")){
        menuItems.push({icon: 'qrcode', title:'Gift Card Admin', menuItemTypes: {jsRoute: '/gift-cards'}})
    }
    if(res.userSecurities.some(obj => obj.securityKey === "ROLE_EMPLOYEE_VEHICLE") || res.userSecurities.some(obj => obj.securityKey === "ROLE_RESIDENT_VEHICLE")){
        menuItems.push({icon: 'vehicle', title:'Vehicles', menuItemTypes: {jsRoute: '/vehicles'}})
    }
    if(!res.userSecurities.some(obj => obj.securityKey === "ROLE_IT")){
        menuItems = menuItems.filter(item => !item.menuItemTypes.nativeRoute?.includes("IT"))
    }
    cookies.set("defaultName", res.defaultAppLocation?.locationDisplayName)
    if(res.userSecurities.some(obj => obj.securityKey === "ROLE_ADMIN")){
        menuItems.push({icon: 'admin', title:'App Admin', menuItemTypes: {jsRoute: '/app-admin'}})
    }
    if(res.userSecurities.some(obj => obj.securityKey === "ROLE_VENDORS") || res.userSecurities.some(obj => obj.securityKey === "ROLE_VENDORS_ADMIN")){
        menuItems.push({icon: 'business', title:'Vendors', menuItemTypes: {jsRoute: '/vendors'}})
    }
    localStorage.setItem("defaultLocation", JSON.stringify(res.defaultAppLocation))
    localStorage.setItem("user", JSON.stringify(res.userSecurities))
    localStorage.setItem("menu", JSON.stringify(menuItems))

    return menuItems;
}

const clearLocalStorage = () => {
    localStorage.removeItem("user")
    localStorage.removeItem("menu")
}

const hasAdmin = (role) => {
    const userLocalStorage = JSON.parse(localStorage.getItem("user"));
    if (userLocalStorage) {
        return userLocalStorage.some(item => item.securityKey === role);
    }
    return false;
}

const getName = () => {
    return cookies.get("defaultName") ?? "GSC"
}

const getDefaultLocation = () => {
    return JSON.parse(localStorage.getItem("defaultLocation"))
}

export {
    loadMenu,
    clearLocalStorage as clearCookie,
    hasAdmin,
    getName,
    getDefaultLocation
}
